<template>
    <navbar></navbar>
    <slot></slot>
</template>

<script>
import Navbar from '../../components/common/Navbar.vue';

export default {
    name: 'MainLayout',
    components: {
        Navbar,
        // Footer,
    },
};
</script>
