<template>
    <header class="main-menu">
        <nav class="absolute top-0 z-[1000] w-full z-50 mobile-menu-bg py-[26px] mobile-nav">
            <div class="container">
                <div class="lg:flex items-center gap-6" :class="{ 'justify-between' : $props.showMenu, 'justify-center': !$props.showMenu }">
                    <div class="flex items-center" :class="{ 'justify-between' : $props.showMenu, 'justify-center': !$props.showMenu }">
                        <router-link to="/" v-if="$props.altLogo===false">
                            <img src="assets/images/logo.png" class="" alt="logo" style="max-height:6vh" />
                        </router-link>
                        <router-link to="/" v-else>
                            <img src="assets/images/logo-alt.png" class="" alt="logo" style="max-height:8vh" />
                        </router-link>
                        <button class="text-end text-white mobile-menu-button lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-8 h-8">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </button>
                    </div>
                    <div id="navbarNav" class="mobile-menu hidden lg:block" v-if="$props.showMenu">
                        <ul class="lg:space-x-8 space-y-4 lg:space-y-0 lg:flex items-center mt-6 lg:mt-0">
                            <li class="active home">
                                <a href="#home" class="uppercase border-b-[2px] !border-dark hover:!border-custom text-sm py-3 text-white relative duration-300">
                                    Home
                                </a>
                            </li>
                            <li class="about">
                                <a href="#about" class="uppercase border-b-[2px] !border-dark hover:!border-custom text-sm py-3 text-white relative duration-300">
                                    About
                                </a>
                            </li>
                            <li class="services">
                                <a href="#services" class="uppercase border-b-[2px] !border-dark hover:!border-custom text-sm py-3 text-white relative duration-300">
                                    Servizi
                                </a>
                            </li>
                            <li class="work">
                                <a href="#work" class="uppercase border-b-[2px] !border-dark hover:!border-custom text-sm py-3 text-white relative duration-300">
                                    Portfolio
                                </a>
                            </li>
                            <li class="blog">
                                <a href="#blog" class="uppercase border-b-[2px] !border-dark hover:!border-custom text-sm py-3 text-white relative duration-300">
                                    Blog
                                </a>
                            </li>
                            <li class="contact">
                                <a href="#contact" class="uppercase border-b-[2px] !border-dark hover:!border-custom text-sm py-3 text-white relative duration-300">
                                    Contatti
                                </a>
                            </li>
                            <li>
                                <a href="#contact" class="btn-purple-white lg:!ml-11">Scrivimi!</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>    </header>

</template>

<script>
export default {
    name: 'Navbar',
    props: {
        showMenu: {
            type: Boolean,
            default: true,
        },
        altLogo: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
